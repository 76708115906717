import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Textblock from '../components/textblock/Textblock';
import Fullblock from '../components/fullblock/Fullblock';
import ContactForm from '../components/contactform/ContactForm';

export default () => (
  <Layout>
    <Helmet
      title="NathanGriffen // eternityDigital :: Digital Designer // Front End Web Developer"
      meta={[
        {
          name: 'description',
          content:
            'Over the past 10 years I have been responsible for the majority of digital media production at Atec Design. In particular the design, building and maintenance for a number of websites and accompanying services such as hosting and email. This includes building websites from initial framework through to completion and updating existing sites. Other duties include day to day accounts, invoicing and establishing strong relationships with clients being trusted to always deliver a personalised and dedicated service.',
        },
        {
          name: 'keywords',
          content: 'digital media, design, websites, dedicated service, about me',
        },
      ]}
    />
    <main>
      <Fullblock>
        <h2>About</h2>
      </Fullblock>
      <Textblock textblockTitle="What Have I Been Working On?">
        <p>
          Over the past 10 years I have been responsible for the majority of digital media
          production at
          {' '}
          <a href="https://www.atecdesign.co.uk" target="_blank" rel="noopener noreferrer">
            Atec Design
          </a>
          . In particular the design, building and maintenance for a number of websites and
          accompanying services such as hosting and email. This includes building websites from
          initial framework through to completion and updating existing sites. Other duties include
          day to day accounts, invoicing and establishing strong relationships with clients being
          trusted to always deliver a personalised and dedicated service.
        </p>
        <p>
          I have also undertaken a number of smaller local projects for my own brand &quot;eternity
          Digital&quot;. The brand has a broader creative scope, including designing and producing
          business cards, leaflets, posters and other office stationery.
        </p>
        <h5>
          If you&apos;d like to see some examples of my work please take a look at my
          {' '}
          <Link to="/projects">Projects</Link>
          {' '}
page.
        </h5>
      </Textblock>
    </main>
    <ContactForm formTitle="Let's talk!" />
  </Layout>
);
